import {configureStore} from '@reduxjs/toolkit';
import {SocketMiddleware} from './middleware/SocketMiddleware';
import {pubQuizReducer} from './slices/PubQuizSlice';
import {sessionReducer} from './slices/SessionSlice';

export const store = configureStore({
    reducer: {
        session: sessionReducer,
        pubQuiz: pubQuizReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .prepend(SocketMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
