import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IParticipantModel} from '../model/ParticipantModel';
import {RootState} from '../store';
import {QuizStates} from '../model/QuizStates';
import {IPubQuizState} from '../model/PubQuizStateModel';
import {QuestionModel} from '../model/QuestionModel';
import {QuizResultsModel} from '../model/QuizResults';

const initialState: IPubQuizState = {
    participants: [],
    state: QuizStates.Loading,
    currentQuestion:  undefined,
    answer: undefined,
    quizResult: undefined,
};

export const pubQuizSlice = createSlice({
    name: 'PubQuiz',
    initialState,
    reducers: {
        addParticipant: (state, action: PayloadAction<IParticipantModel>) => {

            // filter out if it is there already
            const participants = state.participants.filter(
                participant => participant.participantId !== action.payload.participantId);

            // add new participant
            participants.push(action.payload);
            return {
                ...state,
                participants
            };
        },

        removeParticipant: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                participants: state.participants.filter(participant => participant.participantId !== action.payload)
            };

        },

        changeState: (state, action: PayloadAction<IPubQuizState>) => {
            return {
                ...state,
                ...action.payload
            };
        },
        newQuestion: (state, action: PayloadAction<QuestionModel>) => {
            return {
                ...state,
                state: QuizStates.Running,
                currentQuestion:  action.payload,
                answer: undefined
            };
        },
        startingQuiz: (state: IPubQuizState) => {
            return {
                ...state,
                state: QuizStates.Starting

            };
        },
        restartingQuiz: (state: IPubQuizState) => {
            return {
                ...state,
                state: QuizStates.ReStarting

            };
        },
        finishedQuiz: (state: IPubQuizState, action: PayloadAction<QuizResultsModel>) => {
            return {
                ...state,
                state: QuizStates.Finished,
                quizResult: action.payload
            };
        },
        answerCurrent: (state: IPubQuizState, action: PayloadAction<number>) => {
            return {
                ...state,
                answer: action.payload
            };
        }
    }
});


export const {
    addParticipant,
    removeParticipant,
    changeState,
    startingQuiz,
    restartingQuiz,
    newQuestion,
    answerCurrent,
    finishedQuiz
} = pubQuizSlice.actions;

export const pubQuizReducer = pubQuizSlice.reducer;


export const getParticipants = (state: RootState): IParticipantModel[] => state.pubQuiz.participants;
export const getQuizState = (state: RootState): QuizStates => state.pubQuiz.state;
export const getCurrentQuestion = (state: RootState): QuestionModel | undefined => state.pubQuiz.currentQuestion;
export const getCurrentAnswer = (state: RootState): number | undefined => state.pubQuiz.answer;
export const getQuizResult = (state: RootState): QuizResultsModel | undefined => state.pubQuiz.quizResult;
