import './Participants.css';
import {useSelector} from 'react-redux';
import {getParticipants} from '../../slices/PubQuizSlice';

export function Participants(): JSX.Element {

    const participants = useSelector(getParticipants);


    const participantList = participants.map((participant) =>
        <li className="pill" key={participant.participantId}><span>{participant.name}</span></li>
    );


    return (
        <ul className="participants">
            {participantList}
        </ul>
    );
}
