import './QuestionScreen.css';
import {getCurrentAnswer, getCurrentQuestion} from '../../slices/PubQuizSlice';
import {useAppDispatch, useAppSelector} from '../../hook';
import {answer} from '../../middleware/SocketMiddleware';
import {Timer} from './Timer';


export function QuestionScreen(): JSX.Element {
    const currentQuestion = useAppSelector(getCurrentQuestion);
    const currentAnswer = useAppSelector(getCurrentAnswer);
    const dispatch = useAppDispatch();

    const choose = (id: number): void => {
        dispatch(answer(id));
    };

    const defaultClass = currentAnswer === undefined ? 'pill' : 'pill pill-disabled';

    return (
        <div className="questionScreen">
            <h1>
                {currentQuestion?.question ?? 'UNDEFINED'}
            </h1>
            <ul className="answers">
                <button
                    className={currentAnswer === 0 ? 'pill pill-chosen' : defaultClass}
                    onClick={currentAnswer === undefined ? () => {
                        choose(0);
                    } : undefined}><span>{currentQuestion?.option0}</span></button>
                <button className={currentAnswer === 1 ? 'pill pill-chosen' : defaultClass}
                    onClick={currentAnswer === undefined ? () => {
                        choose(1);
                    } : undefined}>
                    <span>{currentQuestion?.option1}</span></button>
                <button className={currentAnswer === 2 ? 'pill pill-chosen' : defaultClass}
                    onClick={currentAnswer === undefined ? () => {
                        choose(2);
                    } : undefined}>
                    <span>{currentQuestion?.option2}</span></button>
                <button className={currentAnswer === 3 ? 'pill pill-chosen' : defaultClass}
                    onClick={currentAnswer === undefined ? () => {
                        choose(3);
                    } : undefined}>
                    <span>{currentQuestion?.option3}</span></button>
            </ul>
            <div className="bottomBar">
                <Timer endTimestamp={currentQuestion?.endTimestamp} duration={currentQuestion?.duration ?? 10}/>
            </div>
        </div>
    );
}
