import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { WsConnect } from '../middleware/SocketAction';
import { updateMe } from '../slices/SessionSlice';

interface Props {
    children: React.ReactNode;
}


export function ContextProvider({ children }: Props): JSX.Element {

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('sessionId');
    const participantId = searchParams.get('participantId');
    const name =searchParams.get('name');

    if (sessionId == null || participantId == null || name == null ) {
        return (<p>Missing request parameters</p>);
    }

    const sessionModel = {sessionId, participantId, name};

    // update state
    dispatch(updateMe(sessionModel));

    // allow to connect
    dispatch(WsConnect(sessionModel));

    return (<>{children}</>);
}
