import {ISessionModel, sessionsEqual} from '../model/SessionModel';
import {
    addParticipant,
    answerCurrent,
    changeState, finishedQuiz,
    newQuestion,
    removeParticipant, restartingQuiz,
    startingQuiz
} from '../slices/PubQuizSlice';
import {changeConnection, ISessionState} from '../slices/SessionSlice';
import {AppDispatch, RootState} from '../store';
import {AnyAction, Dispatch, Middleware, MiddlewareAPI} from '@reduxjs/toolkit';
import {IPubQuizState} from '../model/PubQuizStateModel';
import {QuizStates} from '../model/QuizStates';

let socketSession: WebSocket | undefined;
let currentSessionModel: ISessionModel | undefined;
let clearTimer: any;

type StoreMiddleware = MiddlewareAPI<Dispatch<AnyAction>, { session: ISessionState; pubQuiz: IPubQuizState }>;

const openWebSocket = (store: StoreMiddleware, sessionModel: ISessionModel): void => {
    socketSession = new WebSocket(`wss://${  process.env.REACT_APP_WEB_SOCKET_URL  }?sessionId=${
        sessionModel.sessionId
    }&name=${  sessionModel.name
    }&participantId=${  sessionModel.participantId}`);

    socketSession.onopen = onConnect(store);
    socketSession.onmessage = onMessage(store);
    socketSession.onerror = onError(store);
    socketSession.onclose = onClose(store);

};

const onMessage = (store: StoreMiddleware) => (event: any) => {
    const eventData = JSON.parse(event.data);

    console.log(eventData);
    if (eventData.state !== undefined) {

        store.dispatch(changeState(eventData.state));

    }
    if (eventData.delete !== undefined && eventData.delete.participants !== undefined) {
        for (const participant of eventData.delete.participants) {
            store.dispatch(removeParticipant(participant.participantId));

        }
    }
    if (eventData.change !== undefined && eventData.change.participants !== undefined) {
        for (const participant of eventData.change.participants) {
            store.dispatch(addParticipant(participant));
        }
    }

    if (eventData.newQuestion !== undefined) {
        store.dispatch(newQuestion(eventData.newQuestion));
    }

    if (eventData.quizResult !== undefined ) {
        store.dispatch(finishedQuiz(eventData.quizResult));
    }

};

const onError = (_: StoreMiddleware) => (event: any) => {
    console.log('Socket Error: ', event);
};

const onClose = (store: StoreMiddleware) => () => {
    store.dispatch(changeConnection(false));
    clearPingTimer();
};

const onConnect = (store: StoreMiddleware) => (_: any) => {
    if (!socketSession) {return;}

    store.dispatch(changeConnection(true));

    requestState();
    setPingTimer();
};

const ping = async (): Promise<void> => {
    if (!socketSession) {return;}
    await sendToWebsocket('ping');
    setPingTimer();
};

const setPingTimer = (): void => {
    clearTimer = setTimeout(ping, 60000);
};

const clearPingTimer = (): void => {
    if (clearTimer) {
        clearTimeout(clearTimer);
        clearTimer = undefined;
    }
};

const requestState = async (): Promise<void> => {
    if (!socketSession) {return;}
    await sendToWebsocket('state');
};

const sendToWebsocket = async (action: string, data: any | undefined = undefined): Promise<void> => {
    if (!socketSession) {return;}

    const message: { action: string; method: string; data?: any }
            = {action: 'message', method: action};
    if (data !== undefined) {
        message.data = data;
    }
    await socketSession.send(JSON.stringify(message));
};

export const SocketMiddleware: Middleware = store => next => action => {

    switch (action.type) {
        case 'WS_CONNECT':
            if (socketSession) {
                if (currentSessionModel !== undefined &&
                    sessionsEqual(action.sessionModel, currentSessionModel)) {
                    return;
                }

                socketSession.close();
                socketSession = undefined;
            }

            currentSessionModel = action.sessionModel;
            openWebSocket(store, action.sessionModel);
            break;
        default:
            return next(action);
    }
};

export function startQuiz() {
    return async function startQuizThunk(dispatch: AppDispatch, getState: () => RootState) {
        const state = getState();
        if (state.pubQuiz.state === QuizStates.Lobby) {
            dispatch(startingQuiz());
            await sendToWebsocket('startQuiz');
        }
    };
}

export function restartQuiz() {
    return async function restartQuizThink(dispatch: AppDispatch, getState: () => RootState) {
        const state = getState();
        if (state.pubQuiz.state === QuizStates.Finished) {
            dispatch(restartingQuiz());
            await sendToWebsocket('restartQuiz');
        }
    };
}

export function answer(id: number) {
    return async function startQuizThunk(dispatch: AppDispatch, getState: () => RootState) {
        const state = getState();
        if (state.pubQuiz.state === QuizStates.Running) {
            dispatch(answerCurrent(id));
            await sendToWebsocket('answer', {id: state.pubQuiz.currentQuestion?.id, answer: id});
        }
    };
}
