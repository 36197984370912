
import './App.css';
import { ContextProvider } from './context/ContextProvider';
import {PubQuiz} from './views/PubQuiz';
import { BrowserRouter as Router } from 'react-router-dom';

export function App(): JSX.Element {
    return (
        <Router>
            <ContextProvider>
                <PubQuiz />
            </ContextProvider>
        </Router>
    );
}

