import {useSelector} from 'react-redux';
import {getQuizState} from '../slices/PubQuizSlice';
import {QuizStates} from '../model/QuizStates';
import {LoadingScreen} from './start/LoadingScreen';
import {LobbyScreen} from './start/LobbyScreen';
import {QuestionScreen} from './quiz/QuestionScreen';
import {QuizResultsScreen} from './quiz/QuizResultsScreen';

export function PubQuiz(): JSX.Element {
    const state = useSelector(getQuizState);

    switch (state) {
        case QuizStates.Loading:
            return (<LoadingScreen/>);
        case QuizStates.Lobby:
        case QuizStates.Starting:
            return (<LobbyScreen/>);
        case QuizStates.Running:
            return (<QuestionScreen/>);
        case QuizStates.Finished:
        case QuizStates.ReStarting:
            return (<QuizResultsScreen/>);
    }

    return (<p> TODO </p>);

}
